export var urls = {
    "": [],
    "About BioCentury": [
        {
            title: "Company Overview",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/about/company-overview")
        },
        {
            title: "Management",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/about/management")
        },
        {
            title: "Editorial & Research",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/about/editorial-research")
        },
        {
            title: "Scientific Advisory Board",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/about/scientific-advisory-board")
        }, 
    ],
    "Privacy & Legal": [
        {
            title: "User Agreement",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/privacy-legal/user-agreement")
        },
        {
            title: "Terms of Use",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/privacy-legal/terms-of-use")
        },
        {
            title: "Privacy Policy",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/privacy-legal/privacy-policy")
        },
        {
            title: "Cookie Policy",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/privacy-legal/cookie-policy")
        },
        {
            title: "California Privacy Rights",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/privacy-legal/california-privacy-rights")
        },
        {
            title: "Use of Images & Market Data",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/privacy-legal/use-of-images-market-data")
        },
        {
            title: "Reprints & Permissions",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/privacy-legal/reprints-permissions")
        }, 
    ],
    Contact: [
        {
            title: "Contact Us",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/contact")
        },
        {
            title: "Advertising & Sponsorship",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/contact/sponsorship-branding")
        },
        {
            title: "Customer Resource Center",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/customer-resource-center")
        },
        {
            title: "Career Opportunities",
            href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/contact/career-opportunities")
        },
        {
            title: "Forgot Password",
            href: "https://identity.biocentury.com/user/support/password/forgot"
        }, 
    ]
};
export var socialLinks = [
    {
        title: "YouTube",
        href: "https://www.youtube.com/@biocenturyvideo487",
        icon: "https://media.graphassets.com/3dpsv7ZpQjqGwy6qdaQw"
    },
    {
        title: "LinkedIn",
        href: "https://www.linkedin.com/company/biocentury",
        icon: "https://media.graphassets.com/MLR8OazgQpGLt8OucyoR"
    },
    {
        title: "X",
        href: "https://x.com/BioCentury",
        icon: "https://media.graphassets.com/ZIFHHRCRSRW6dsur8nS6"
    },
    {
        title: "RSS",
        href: "".concat(process.env.NEXT_PUBLIC_BIOCENTURY_URL, "/rss"),
        icon: "https://media.graphassets.com/VllQ6loXRlWKiqO9olQW"
    }, 
];
